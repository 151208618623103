import {
  ArrowLeftIcon,
  ArrowRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
// import { Note, Patient, Sex } from "@prisma/client";
import { SetStateAction, useEffect, useState, useContext } from "react";
import { WritableAtom, atom, useAtom } from "jotai";
import Layout from "../components/Layout";
import Spinner from "../components/Spinner";
import Table from "../components/Table";
import { focusAtom } from "jotai-optics";
import { twMerge } from "tailwind-merge";
import { useUser } from "@clerk/clerk-react";

import { DataContext } from "../dataContext/DataContext";

import { useNavigate } from "react-router-dom";

const Sex = {
  MALE: "MALE",
  FEMALE: "FEMALE",
};

const searchFilterAtom = atom({
  date: undefined,
  locationType: undefined,
  location: undefined,
  sex: undefined,
});

const pageAtom = atom(1);
const Home = () => {
  const [sex, setSex] = useState("");
  console.log("Current Sex:", sex);

  const [location, setLocation] = useState("Select...");
  console.log("Current Location:", location);

  const [date, setDate] = useState(null);
  console.log("Current Date:", date);

  const handleSexChange = (newSex) => {
    setSex(newSex);
  };

  const handleLocationChange = (newLocation) => {
    setLocation(newLocation);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const navigation = useNavigate();
  const [rec, setRec] = useState(false);

  const { user, isLoaded: isUserLoaded, isSignedIn } = useUser();

  const {
    getNotes,
    allQueryResult,
    setAllQueryResult,
    sessionToken,
    filteredData,
    setFilteredData,
  } = useContext(DataContext);

  useEffect(() => {
    let tempFilteredData = allQueryResult?.data?.notes;
    if (sex !== "") {
      tempFilteredData = tempFilteredData?.filter((item) => {
        if (sex === "") return true;
        return item.patient.sex === sex;
      });
    }
    if (location !== "Select...") {
      tempFilteredData = tempFilteredData?.filter((item) => {
        if (location === "Select...") return true;
        return item.locationType.toUpperCase() === location;
      });
    }
    if (date !== null) {
      tempFilteredData = tempFilteredData?.filter((item) => {
        if (date === null) return true;
        else {
          const selectedDate = date.split(" | ")[0];

          const month = String(item.createdAt.getMonth() + 1); // getMonth() is zero-based
          const day = String(item.createdAt.getDate()).padStart(2, "0");
          const year = item.createdAt.getFullYear();

          const dataDate = `${month}/${day}/${year}`;
          console.log("Data Date", dataDate);
          //const dataDate = item.createdAt.split(" | ")[0];

          return selectedDate === dataDate;
        }
      });
    }
    setFilteredData(tempFilteredData);
  }, [sex, location, date]);

  // setFilteredData(tempFilteredData);

  useEffect(() => {
    getNotes();
  }, [sessionToken]);

  const [searchTerm, setSearchTerm] = useState();
  const [searchFilters, setSearchFilters] = useAtom(searchFilterAtom);

  const [pageValue, setPageValue] = useAtom(pageAtom);
  const pageSize = 10;

  const hasPrevPage = pageValue > 1;
  const hasNextPage =
    allQueryResult.data?.notes &&
    allQueryResult.data.notes.length == pageSize &&
    pageValue * pageSize < allQueryResult.data.totalNotes;

  const isResultEmpty =
    allQueryResult.isSuccess && allQueryResult.data.notes.length === 0;
  const isSearching =
    searchTerm ||
    searchFilters.date ||
    searchFilters.locationType ||
    searchFilters.location ||
    searchFilters.sex;

  const columns = {
    "Patient Identifier": (n) => {
      const identifier = n.patient?.identifier || "";
      const age = n.patient?.age || "";
      const sex = n.patient?.sex === Sex.MALE ? "♂" : "♀";
      return `${identifier} | ${age} ${sex}`.trim();
    },
    "Location Identifier": (n) => {
      const locationType = n.locationType || "";
      const location = n.location || "";
      return `${locationType} #${location}`.trim();
    },
    "Created At": (n) => {
      if (!n.createdAt) return "";
      const date = n.createdAt.toLocaleDateString() || "";
      const time =
        n.createdAt.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }) || "";
      return `${date} | ${time}`.trim();
    },
    ...(isUserLoaded && user?.publicMetadata.isSuperAdmin
      ? {
          "Author Fullname": (n) => {
            if (!n.author) return "";
            const firstName = n.author.firstName || "";
            const lastName = n.author.lastName || "";
            return `${firstName} ${lastName}`.trim() || "";
          },
          "Author Email": (n) => {
            if (!n.author || !n.author.emailAddresses) return "";
            return n.author.emailAddresses[0]?.emailAddress || "";
          },
        }
      : {}),
  };
  //   useEffect(() => {
  //     if (isSignedIn) {
  //       Sentry.setUser({
  //         id: user.id,
  //         email: user.emailAddresses.at(0)?.emailAddress,
  //       });

  //       analytics.identify(user.id, {
  //         email: user.emailAddresses.at(0)?.emailAddress,
  //       });
  //     }
  //   }, []);

  return (
    <>
      {/* <Head>
        <title>Time by DrH - All your notes</title>
      </Head> */}
      <Layout>
        <div>
          <div className="flex items-start justify-between">
            <div className="sm:flex sm:items-center">
              <div className="max-w-3xl sm:flex-auto">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Your patient notes
                </h3>
                <p className="mt-2 text-sm text-gray-700">
                  Discover detailed summaries of medical patient conversations
                  on our web app. Explore transcriptions that offer valuable
                  insights into healthcare encounters, empowering professionals
                  with crucial information.
                </p>
              </div>
            </div>

            {/* Only show filters if user is not new and has any notes to see */}
            {(!isResultEmpty || isSearching) && (
              <div className="flex max-w-lg flex-col">
                <div className="flex w-full justify-end">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <input
                      id="search"
                      className="block w-full rounded-md bg-white px-4 py-1.5 text-gray-900 outline-none drop-shadow-md sm:text-sm sm:leading-6"
                      placeholder="Search"
                      type="search"
                      name="search"
                      maxLength={100}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setPageValue(1);
                      }}
                      onClick={() => {}}
                      value={searchTerm}
                    />
                  </div>
                </div>
                <div className="w-full max-w-lg sm:flex-auto">
                  <div className="flex flex-col justify-end">
                    <div className="flex items-center justify-end gap-x-3">
                      <DateFilter
                        initialDate={date}
                        onDateChange={handleDateChange}
                      />
                      <LocationTypeFilter
                        initialLocation={location}
                        onLocationChange={handleLocationChange}
                      />

                      <SexFilter
                        initialSex={sex}
                        onSexChange={handleSexChange}
                      />
                    </div>
                    <button
                      className="ms-auto mt-3 h-fit w-fit rounded-md bg-gray-300 py-1 pl-1 pr-2 hover:bg-gray-400"
                      onClick={() => {
                        setSex("");
                        setLocation("Select...");
                        setDate(null);
                        setSearchFilters({
                          date: undefined,
                          locationType: undefined,
                          location: undefined,
                          sex: undefined,
                        });
                      }}
                    >
                      <span className="flex text-sm text-white">
                        <XMarkIcon className="-mb-0.5 me-0.5 w-4 stroke-2" />
                        Clear Filters
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Show a loading indicator if allQueryResult.isLoading is true */}
        {allQueryResult.isLoading && (
          <div className="mt-8">
            <div className="flex flex-col items-center justify-center">
              <Spinner />
              <h3 className="mt-2 text-xs font-light leading-6 text-gray-900">
                Loading...
              </h3>
            </div>
          </div>
        )}

        {/* Show an error if allQueryResult.error is defined */}

        {isResultEmpty &&
          (isSearching ? (
            <div className="mt-14">
              <div className="flex flex-col items-center justify-center">
                <img
                  src="/images/filter-no-results.webp"
                  alt="Doctor checking pregnant lady"
                  className="max-w-xs"
                />
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  No results found
                </h3>
                <p className="mt-2 text-sm text-gray-700">
                  Try changing your search terms or filters.
                </p>
              </div>
            </div>
          ) : (
            <div className="mt-8">
              {/* Show an empty state and download links for Google Play and AppStore*/}
              <div className="flex items-center justify-center gap-x-8">
                <div className="flex flex-col items-center">
                  <img
                    src="/images/doctor-checking-pregnant-lady.svg"
                    alt="Doctor checking pregnant lady"
                    className="max-w-xs"
                  />
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Looks like you&apos;re just getting started!
                  </h3>
                  <p className="mt-2 text-sm text-gray-700">
                    Join us to unlock a world of patient insights and enhance
                    medical understanding.
                  </p>

                  <p className="text-sm text-gray-700">
                    Start by downloading our mobile app now!
                  </p>

                  <div className="mt-4 flex flex-row">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.drhaydar.time"
                      className="mr-4"
                      target="_blank"
                      rel="noferrer noreferrer"
                    >
                      <img
                        src="/images/google-play-badge.png"
                        alt="Download on Google Play"
                        className="h-12"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/uy/app/time-by-dr-haydar/id6464188878"
                      className="mr-4"
                      target="_blank"
                      rel="noferrer noreferrer"
                    >
                      <img
                        src="/images/app-store-badge.svg"
                        alt="Download on App Store"
                        className="h-12"
                      />
                    </a>
                  </div>

                  {/* A section titled "What do I do next?" where follow up steps are described */}
                  <div className=" mt-8">
                    <h3 className="text-center text-base font-semibold leading-6 text-gray-900">
                      What do I do next?
                    </h3>
                    <p className="mt-2 text-center text-sm text-gray-700">
                      1. Download our mobile app
                    </p>
                    <p className="mt-2 text-center text-sm text-gray-700">
                      2. Sign in with the same user
                    </p>
                    <p className="mt-2 text-center text-sm text-gray-700">
                      3. Transcribe conversations and see them here!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {allQueryResult.isSuccess && (
          <Table
            data={filteredData}
            columns={columns}
            hrefer={(note) => `/notes/${note.id}`}
            dateAccessor={(note) => note.createdAt}
            searchBySpecificDate={searchFilters?.date !== undefined}
          />
        )}

        {(hasPrevPage || hasNextPage) && (
          <div
            className={twMerge(
              "mx-auto mt-5 flex h-12 w-40 items-center gap-8",
              hasPrevPage && hasNextPage
                ? "justify-center"
                : hasNextPage
                ? "justify-end"
                : "justify-start"
            )}
          >
            {hasPrevPage && (
              <button
                className="mb-8 mt-8 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                onClick={() => setPageValue(pageValue - 1)}
              >
                <ArrowLeftIcon className="w-5 stroke-2" />
              </button>
            )}

            <span>{pageValue}</span>

            {hasNextPage && (
              <button
                className="mb-8 mt-8 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                onClick={() => {
                  setPageValue(pageValue + 1);
                }}
              >
                <ArrowRightIcon className="w-5 stroke-2" />
              </button>
            )}
          </div>
        )}
      </Layout>
    </>
  );
};

function formatDate(date) {
  return `${date.toLocaleDateString()}`;
}

function DateFilter({ initialDate, onDateChange }) {
  const [dateValue, setDateValue] = useState(initialDate);

  const handleDateChange = (e) => {
    const selectedDate = e.currentTarget.valueAsDate;
    if (selectedDate !== null) {
      const formattedDate = formatDate(selectedDate); // Format the selected date
      setDateValue(selectedDate); // Update state with the Date object
      onDateChange(formattedDate); // Pass the formatted date to the callback
    } else {
      setDateValue(undefined);
      onDateChange(undefined);
    }
  };

  return (
    <div className="mt-4 flex flex-col">
      <h2 className="mb-1 text-sm">Date</h2>
      <div className="flex items-center justify-around">
        <input
          type="date"
          value={dateValue ? dateValue.toISOString().split("T")[0] : ""}
          onChange={handleDateChange}
          className="border-1 rounded-md border-gray-300 px-4 py-1 text-gray-900 outline-none sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );
}

function LocationTypeFilter({ initalLocation, onLocationChange }) {
  const [locationTypeValue, setLocationTypeValue] = useState(initalLocation);

  const handleLocationChange = (newLocation) => {
    onLocationChange(newLocation);
  };

  const LocationType = ["ROOM", "HALL", "CHAIR", "OTHER"];

  return (
    <div className="mt-4 flex flex-col">
      <h2 className="mb-1 text-sm">Location Type</h2>
      <div className="flex items-center justify-around">
        <select
          className="rounded-md border border-gray-300 py-1 pr-8 text-gray-900 outline-none sm:text-sm sm:leading-6"
          value={locationTypeValue || "Select..."}
          onChange={(e) => {
            setLocationTypeValue(e.target.value);
            handleLocationChange(e.target.value);
          }}
        >
          <option value="Select..." disabled hidden>
            Select...
          </option>
          {Object.values(LocationType).map((v) => (
            <option value={v} key={v}>{`${v.slice(0, 1)}${v
              .slice(1)
              .toLocaleLowerCase()}`}</option>
          ))}
        </select>
      </div>
    </div>
  );
}

function SexFilter({ initialSex, onSexChange }) {
  const [sexValue, setSexValue] = useState(initialSex);

  const handleSexChange = (newSex) => {
    // setSexValue(newSex);
    onSexChange(newSex);
  };

  return (
    <div className="mt-4 flex flex-col">
      <h2 className="mb-1 text-sm">Sex</h2>
      <div className="flex items-center justify-around">
        <button
          className={twMerge(
            "mr-0.5 rounded-l-md bg-blue-500 px-2 py-1 text-white",
            initialSex === "MALE" ? "bg-blue-600" : "bg-blue-500"
          )}
          onClick={() => handleSexChange("MALE")}
        >
          ♂
        </button>
        <button
          className={twMerge(
            "mr-0.5 rounded-r-md bg-blue-500 px-2 py-1 text-white",
            initialSex === "FEMALE" ? "bg-blue-600" : "bg-blue-500"
          )}
          onClick={() => handleSexChange("FEMALE")}
        >
          ♀
        </button>
      </div>
    </div>
  );
}

export default Home;
