import { professionGroups } from "../lib/professions";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import { useState, useEffect, useContext } from "react";
import { useUser, useSession } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../dataContext/DataContext";

const formatGroupLabel = (data) => (
  <div className="flex items-center justify-between">
    <span>{data.label}</span>
    <span className="min-w-1 inline-block rounded-full bg-gray-200 px-2 text-center text-xs font-normal leading-none text-blue-900">
      {data.options.length}
    </span>
  </div>
);

const OnboardingPage = () => {
  const { user } = useUser();
  const { session } = useSession();
  const [sessionToken, setSessionToken] = useState(null);
  const [profession, setProfession] = useState(null);
  const [referrer, setReferrer] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const navigation = useNavigate();

  const { updateProfession } = useContext(DataContext);

  useEffect(() => {
    console.log("USER", user);
  }, [user]);

  const handleUpdate = async () => {
    updateProfession(profession, referrer);

    // if (!session) {
    //   console.error("No session available");
    //   return;
    // }
    // try {
    //   const response = await axios.post(
    //     "http://localhost:4000/api/user/profession",
    //     { profession: profession.value, userId: user.id },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${sessionToken}`,
    //       },
    //     }
    //   );
    //   console.log(response.data);
    // } catch (error) {
    //   console.error("Error updating profession:", error);
    // }
  };

  return (
    <>
      <main className="flex h-screen w-screen items-center justify-center">
        <div className="mx-4 flex w-full max-w-lg flex-col items-center justify-around rounded-lg bg-white px-4 py-8 drop-shadow-md md:w-1/3">
          <img
            src="/images/logo-black.webp"
            alt="Dr Haydar logo black"
            className="w-1/3"
          />
          <div className="space-y-4">
            <h1 className="text-center text-3xl font-semibold">Welcome!</h1>
            <h2 className="text-center text-2xl font-medium">
              What&apos;s your medical speciality?
            </h2>
          </div>

          <div className="grid w-full max-w-xs gap-4 py-8">
            <Select
              placeholder="Emergency Medicine, Primary Care"
              value={profession}
              onChange={(selectedOption) => setProfession(selectedOption)}
              options={professionGroups}
              formatGroupLabel={formatGroupLabel}
            />

            <input
              type="text"
              value={referrer}
              onChange={(e) => setReferrer(e.target.value)}
              placeholder="Invitation code"
              className="text-md h-[38px] w-full rounded-md border-[1px] border-gray-300 px-4 py-2"
            />
          </div>

          {/* Display the checkbox for accepting terms and conditions */}
          <div className="mb-8 flex w-[80%] items-center space-x-3">
            <input
              onClick={() => setAcceptedTerms(!acceptedTerms)}
              className="rounded-sm"
              type="checkbox"
            />
            <p className="text-sm text-gray-600">
              Accept{" "}
              {/* <Link
                className="text-blue-500"
                href="https://drh.ai/legal/privacy"
              > */}
              privacy policy & terms of service
              {/* </Link> */}. By checking this box you confirm that any
              personal data provided is correct.
            </p>
          </div>

          <button
            className="rounded-md bg-primary px-4 py-2 text-lg text-white disabled:bg-gray-400"
            disabled={!profession || !acceptedTerms}
            onClick={() => {
              handleUpdate();
              navigation("/");
            }}
          >
            <span>Continue</span> <ArrowRightIcon className="inline w-6" />
          </button>
        </div>
      </main>
    </>
  );
};

export default OnboardingPage;
