import React, { createContext, useState, useEffect } from "react";
import { useUser, useSession } from "@clerk/clerk-react";

import axios from "axios";

const DataContext = createContext();

const MyProvider = ({ children }) => {
  //const [user, setUser] = useState(null);

  const [currentNoteID, setCurrentNoteID] = useState(0);
  const [currentFollowUpID, setCurrentFollowUpId] = useState(0);

  const [toEdit, setToEdit] = useState(null);
  const [macros, setMacros] = useState([]);
  const [stripeProducts, setStripeProducts] = useState([]);
  const [subscriptionObject, setSubscriptionObject] = useState({});

  const [data, setData] = useState({
    note: {
      patient: {},
    },
    results: {
      status: `LOADING...`,
    },
    isBlocked: false,
    isReady: true,
  });

  const [allQueryResult, setAllQueryResult] = useState({
    isLoading: true,
    isSuccess: false,
    error: null,
  });

  const [filteredData, setFilteredData] = useState([]);

  // ({
  //   note: {
  //     id: 2,
  //     userId: `2`,
  //     patientId: 2,
  //     locationType: `HOSPITAL`,
  //     location: `Ward 101`,
  //     body: `AM III COOOLLL???`,
  //     noteType: `type1`,
  //     parentNote: null,
  //     isArchived: false,
  //     status: `TRANSCRIBING_ORIGINAL`,
  //     createdAt: `2024-07-31T12:39:55.075Z`,
  //     updatedAt: `2024-08-03T17:42:59.672Z`,
  //     patient: {
  //       id: 2,
  //       name: `Jhoonyy Doe`,
  //       innitials: `JD`,
  //       identifier: `JD Jhoonyy Doe`,
  //       age: 30,
  //       ageType: `YEARS`,
  //       sex: `MALE`,
  //       location: `Ward 101`,
  //       createdAt: `2024-07-31T12:39:54.901Z`,
  //       updatedAt: `2024-07-31T12:39:54.901Z`,
  //     },
  //   },
  //   results: {
  //     status: `COMPLETE`,
  //     summary: `AM III COOOLLL???`,
  //     followup: null,
  //   },
  //   isBlocked: false,
  //   isReady: true,
  // });

  const { session } = useSession();
  const [sessionToken, setSessionToken] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    const fetchSessionToken = async () => {
      if (session) {
        const token = await session.getToken({
          template: `Time-Drh-Backend`,
        });
        setSessionToken(token);
        console.log(`SessionToken`, token);
      }
    };

    fetchSessionToken();
  });

  const getNotes = async () => {
    try {
      const url = user.publicMetadata.isSuperAdmin
        ? `/api/v1/note/admin-notes`
        : `/api/v1/note/app`;

      const response = await axios.get(`${process.env.REACT_APP_URL}${url}`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });
      console.log(`Notes Response`, response.data);

      const transformation = response?.data?.body?.map((data) => {
        return { ...data, createdAt: new Date(data.createdAt) };
      });
      console.log(`Transformation`, transformation);
      const tempQ = {
        isLoading: false,
        isSuccess: true,
        data: {
          notes: transformation,
        },
        totalNotes: response.data.length,

        error: null,
      };
      setAllQueryResult(tempQ);
      setFilteredData(tempQ.data.notes);
    } catch (err) {
      console.log(`NOTES ERRR`);
    }
  };

  const getNoteByID = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/v1/note/${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      console.log(`Response`, response);
      setData({
        note: response?.data?.note,
        results: response?.data?.results,
        isBlocked: false,
        isReady: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateProfession = async (profession, referrer) => {
    if (!session) {
      console.error(`No session available`);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/v1/user/profession`,
        { profession: profession.value, referrer },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error(`Error updating profession:`, error);
    }
  };

  const updateNote = async (obj) => {
    console.log(`Summary`, obj.updated.summary);

    const updateBody = obj.updated.summary;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/api/v1/note/${currentNoteID}/body`,
        { body: updateBody },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      console.log(`Updated Note`, response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomPhrases = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/v1/phrase/`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setMacros(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const createNewPhrase = (macroData) => {
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/phrase`,
        {
          code: macroData.code,
          title: macroData.title,
          content: macroData.content,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getStripeProducts = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/v1/stripe/products`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setStripeProducts(response.data.products);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const createSubscription = (priceId) => {
    return axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/stripe/subscription`,
        { priceId },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setSubscriptionObject(response.data);
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

  // const getNoteByID = async (id) => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/note/${id}`);
  //     console.log(response.data);
  //     setData({
  //       note: response.data.note,
  //       results: response.data.results,
  //       isBlocked: false,
  //       isReady: true,
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <DataContext.Provider
      value={{
        user,
        toEdit,
        setToEdit,
        updateProfession,
        getCustomPhrases,
        createNewPhrase,
        getNotes,
        macros,
        setMacros,
        allQueryResult,
        setAllQueryResult,
        sessionToken,
        data,
        setData,
        getNoteByID,
        currentNoteID,
        setCurrentNoteID,
        currentFollowUpID,
        setCurrentFollowUpId,
        updateNote,
        getStripeProducts,
        stripeProducts,
        createSubscription,
        subscriptionObject,
        filteredData,
        setFilteredData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, MyProvider };
