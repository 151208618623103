import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

function Table({ data, columns, onRowClick, dateAccessor, itemsPerPage = 10 }) {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  // Sort and paginate data
  const paginatedData = useMemo(() => {
    const sortedData = dateAccessor
      ? [...data].sort(
          (a, b) => new Date(dateAccessor(b)) - new Date(dateAccessor(a))
        )
      : data;

    const totalPages = Math.ceil(sortedData.length / itemsPerPage);
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    return {
      currentPageData: sortedData.slice(start, end),
      totalPages,
    };
  }, [data, dateAccessor, currentPage, itemsPerPage]);

  const headers = Object.keys(columns);

  const handleRowClick = (item) => {
    if (onRowClick) {
      onRowClick(item);
    } else if (item.id) {
      navigate(`/notes/${item.id}`);
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th
                      key={header}
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {paginatedData.currentPageData.map((item, index) => (
                  <tr
                    key={item.id || index}
                    onClick={() => handleRowClick(item)}
                    className={`cursor-pointer ${
                      index % 2 === 0 ? "bg-white" : "bg-gray-50"
                    }`}
                  >
                    {headers.map((key) => (
                      <td
                        key={key}
                        className="whitespace-nowrap px-3 py-4 text-sm text-gray-900"
                      >
                        {columns[key](item)}
                      </td>
                    ))}
                  </tr>
                ))}
                {/* Pagination controls in the middle of the table */}
                <tr>
                  <td colSpan={headers.length} className="px-3 py-4">
                    <div className="flex justify-center items-center space-x-4">
                      <button
                        onClick={() =>
                          setCurrentPage((prev) => Math.max(prev - 1, 1))
                        }
                        disabled={currentPage === 1}
                        className="px-4 py-2 border rounded-md disabled:opacity-50 text-sm"
                      >
                        Previous
                      </button>
                      <span className="text-sm">
                        Page {currentPage} of {paginatedData.totalPages}
                      </span>
                      <button
                        onClick={() =>
                          setCurrentPage((prev) =>
                            Math.min(prev + 1, paginatedData.totalPages)
                          )
                        }
                        disabled={currentPage === paginatedData.totalPages}
                        className="px-4 py-2 border rounded-md disabled:opacity-50 text-sm"
                      >
                        Next
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
